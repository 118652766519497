.multi-fill-question-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.multifill-input {
    margin-left: 10px;
    width: 80%;
    display: flex;
}

.user-responses {
    display: flex;
    flex-wrap: wrap;
}

.response-item {
    display: flex;
    align-items: center;
    margin: 4px;
    margin-left: 10px;
    padding: 8px;
    padding-left: 16px;
    background-color: rgba(84, 101, 255, 0.19);
    border-radius: 50px;
    margin-bottom: 10px;
}

.remove-btn {
    margin-left: 8px;
    cursor: pointer;
    color: #202545;
    border: none;
    border-radius: 50%;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-btn,
.submit-btn {
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 8px;
}

.input-field {
    width: 90%;
    height: 46px;
    background-color: rgba(84, 101, 255, 0.19);
    border-radius: 10px;
    padding-left: 20px;
    margin-right: 20px;
    flex: 1;
}

.input-field:focus {
    border-color: #202545;
}
