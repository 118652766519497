/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Styles */

/* Background */
.background-gradient {
  background-image: linear-gradient(to right bottom, #5b63b9, #6b75c6, #7c87d4, #8d9ae1, #9fadee, #a2b2f3, #a6b8f8, #a9bdfd, #9cb6fc, #8fb0fa, #80a9f9, #70a3f7);
}

/* Chatbox */
.chatbox {
  height: 80vh;
  margin-top: 100px;
}

#chat-window {
  height: 80vh;
  overflow-y: auto;
  flex-direction: column-reverse;
  padding: 20px;
  padding-right: 10px;
}

/* Messages */
.chatbot-msg {
  background-color: rgba(115, 115, 115, 0.19);
  max-width: 30vw;
  text-align: left;
}

.user-msg {
  background-color: rgba(84, 101, 255, 0.19);
}

/* Selection questions */
.yn-selection {
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  max-width: 50%;
}

.yn-option {
  border-radius: 22px;
  border-radius: 22px;
  border: 1px solid rgba(32, 37, 69, 0.71);
  background: rgba(174, 174, 174, 0.19);
  color: #202545;
  font-size: 18px;
  text-transform: none;
  font-weight: normal;
  min-width: 74px;
  height: 36px;
  flex: 1;
  margin-right: 10px;
}

.option-selection {
  max-width: 50%;
  margin-left: 10px;
}

.option {
  border-radius: 22px;
  border: 1px solid rgba(32, 37, 69, 0.71);
  background: rgba(174, 174, 174, 0.19);
  color: #202545;
  font-size: 18px;
  text-transform: none;
  font-weight: normal;
  flex: 1;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 1rem;
  height: auto;
}

.selected-option {
  background-color: #202545;
  color: white;
  font-weight: normal;
}

.option:hover,
.yn-option:hover {
  background: #202545;
  color: white;
  font-weight: normal;
}

/* Fill questions */
.fill-question-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Typing animation */
.typing__dot {
  float: left;
  width: 12px;
  height: 12px;
  margin: 2px 4px;
  background: #202545;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

/* Input & Send button */
.input-field {
  width: 90%;
  height: 46px;
  background-color: rgba(84, 101, 255, 0.19);
  border-radius: 10px;
  padding-left: 20px;
  margin-right: 20px;
  flex: 1;
  margin-right: 10px;
}

.send-btn {
  border: none;
  color: white;
}

/* Style for the disabled state */
.btn-info:disabled {
  background-color: #bababa;
  color: #777;
  cursor: not-allowed;
}


.user-input {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px;
}

.submit-btn {
  width: 100%;
  color: white;
}


/* Final page */
.final-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px;
  max-width: 30%;
}

.final-page p {
  margin-bottom: 20px;
}

.final-page button {
  margin-top: 10px;
}

.multifill-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-add-container {
  margin-top: 10px;
    display: flex;
    width: 100%;
    margin-bottom: 8px; /* Add margin between input/add and submit buttons */
}

.multifill-input-field {
    flex: 1;
    height: 46px;
    background-color: rgba(84, 101, 255, 0.19);
    border-radius: 10px;
    padding-left: 20px;
    margin-right: 10px;
    border: 1px solid #ccc;
}

.multifill-input-field:focus {
    border-color: #202545;
}

.add-btn {
    width: 80px; /* Adjust width as needed */
}

.multifill-btn-container {
    width: 100%;
}

.submit-btn {
    width: 100%;
    cursor: pointer;
    background-color: #202545;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px;
}

/* Style for the disabled state */
.submit-btn:disabled {
    background-color: #bababa;
    color: #777;
    cursor: not-allowed;
}

/* disclaimer intro card */
.card
{
  height: 80vh;
  width: 90%;
  margin-top: 60px;

  font-size: 120%
  /* margin-left: 10%;
  margin-right: 10%; */
}


@media screen and (max-width: 480px)
{
  .chatbox {
    height: 90vh;
    width: 40vh;
    margin-top: 30px;
  }
  .card-title
  {
    font-size: 1px;
    /* margin-left: 10%;
    margin-right: 10%; */
  }
  .card
  {
    height: 80vh;
    width: 80%;
    margin-top: 60px;

    font-size: 90%
    /* margin-left: 10%;
    margin-right: 10%; */
 }


}

/* IPAD mini */
@media screen and (max-width: 768px)
{
  .chatbox {
    height: 90vh;
    width: 60vh;
    margin-top: 30px;
  }
  .card-title
  {
    font-size: 35px;
    /* margin-left: 10%;
    margin-right: 10%; */
  }
  .card
  {
    height: 80vh;
    width: 80%;
    margin-top: 60px;

    font-size: 120%
    /* margin-left: 10%;
    margin-right: 10%; */
 }


}

@media screen and (max-width: 667px)
{
  .chatbox {
    height: 90vh;
    width: 60vh;
    margin-top: 30px;
  }
  .card-title
  {
    font-size: medium;
    /* margin-left: 10%;
    margin-right: 10%; */
  }
  .card
  {
    height: 80vh;
    width: 80%;
    margin-top: 60px;

    font-size: small
    /* margin-left: 10%;
    margin-right: 10%; */
 }
 .yn-selection {
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  max-width: 90%;
}
/* These are the yes and no ones  */
.yn-option {
  border-radius: 22px;
  border-radius: 22px;
  border: 1px solid rgba(32, 37, 69, 0.71);
  background: rgba(174, 174, 174, 0.19);
  color: #202545;
  font-size: 18px;
  text-transform: none;
  font-weight: normal;
  min-width: 74px;
  height: 36px;
  flex: 1;
  margin-right: 10px;
}

.option-selection {
  max-width: 80%;
  margin-left: 10px;
}

.option {
  border-radius: 22px;
  border: 1px solid rgba(32, 37, 69, 0.71);
  background: rgba(174, 174, 174, 0.19);
  color: #202545;
  font-size: small;
  text-transform: none;
  font-weight: normal;
  flex: 1;
  margin-right: 10px;
  margin-bottom: 10px;
}
}
